import React, {useState, useEffect } from "react";
import axios from "axios";
import {useAuthHeader} from 'react-auth-kit'
import Accordion from 'react-bootstrap/Accordion';
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPenToSquare, faTrash, faCloudArrowUp, faFileCirclePlus} from '@fortawesome/free-solid-svg-icons';
import sanitizeHtml from 'sanitize-html';

const backend = process.env.REACT_APP_BACKEND_API;


export default function DBEditor({type}) {
  const authHeader = useAuthHeader();
  const token = authHeader();
  
  const [newModel, setNewModel] = useState(false);
  const [newHeader, setNewHeader] = useState("");
  const [newData, setNewData] = useState("");
  
  const [modelList, setModelList] = useState([]);

  const [activeKey, setActiveKey] = useState(null); // Adicione esta linha

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
      try {
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const response = await axios.get(backend + `/${type}`, config);
          setModelList(response.data);
      } catch (error) {
          console.log(error);
      }
  };
      
  const ModelItem = ({ model, token }) => {
    const [editing, setEditing] = useState(false);
    const [header, setHeader] = useState(model.name);
    const [data, setData] = useState(model.data);

    const handleEditClick = () => {
      setEditing(true);
    };

    const handleSaveClick = () => {
        const updatedModel = {
          name: header,
          data: data
        };
        
      try {
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const response = axios.put(backend + `/${type}/` + model._id, updatedModel, config);
          setTimeout(() => {
            fetchData();
          }, 500);
      } catch (error) {
          console.log(error);
      }
      setEditing(false);
      fetchData();
    };
      
  const handleDataChange = (value) => {
      setData(value);
  };
  
  const handleDelete = () => {
      try {
          const config = {
            headers: {
              Authorization: `${token}`,
            },
          };
          const response = axios.delete(backend + `/${type}/` + model._id, config);
          setTimeout(() => {
            fetchData();
          }, 500);

      } catch (error) {
          console.log(error);
      }
  };
    return (
      <Accordion.Item eventKey={model._id}>
          <Accordion.Header>
              {editing ? (
                  <input
                      type="text"
                      value={header}
                      onChange={(e) => setHeader(e.target.value)}
                  />
              ) : (
                  <span>{header}</span>
              )}
          </Accordion.Header> 
          <Accordion.Body>
              {editing ? (
                  <>
                  <FontAwesomeIcon className="mx-1 cursor" onClick={handleSaveClick} icon={faCloudArrowUp} title="Salvar alterações"/>
                  <FontAwesomeIcon className="mx-1 cursor" onClick={handleDelete} icon={faTrash} title="Excluir item" />
                  <ReactQuill
                      value={data}
                      onChange={handleDataChange}
                      modules={{
                          toolbar: [
                            ['bold', 'italic', 'underline'],
                          ],
                      }}
                      formats={[
                          'bold', 'italic', 'underline',
                      ]}
                  />
                  </>                 
              ) : (  
                  <>
                  <FontAwesomeIcon className="mx-1 cursor" onClick={handleEditClick} icon={faPenToSquare}/>
                  <FontAwesomeIcon className="mx-1 cursor" onClick={handleDelete} icon={faTrash}/>
                  
                  <p className="border border-1 border-dark p-2" 
                      dangerouslySetInnerHTML={{__html: sanitizeHtml(model.data)}}></p>
                  </>
              )}
          </Accordion.Body>
      </Accordion.Item>
    );
  };
                                
  const handleNewDataChange = (value) => {
      setNewData(value);
  }
                                
  const handleNewSaveClick = (value) => {
      const newModel = {
          name: newHeader,
          data: newData
        };
        
      try {
          const config = {
            headers: {
              Authorization: token,
            },
          };
          const response = axios.post(backend + `/${type}`, newModel, config);
      } catch (error) {
          console.log(error);
      }
      setNewModel(false);
      setNewHeader("");
      setNewData("");  
      fetchData();
  }
            
  const handleNewClick = () => {
      setNewModel(!newModel);
  }     
  const handleNewDelete = () => {
      setNewModel(false);
      setNewHeader("");
      setNewData("");
  }
  return (
    <Container className="mt-2 mx-auto" style={{ maxWidth: '80%' }}>
      <Row>
        <Col>
            <p>
              <a href="#" onClick={handleNewClick} className="no-link">
                <FontAwesomeIcon className="mx-1 cursor" icon={faFileCirclePlus}/> Criar novo
              </a>
            </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion>
            {modelList.map((model) => (
              <ModelItem model={model} token={token} key={model._id} />
            ))}
            {newModel ? (
                <Accordion.Item eventKey="new">
                    <Accordion.Header>
                        <input
                            type="text"
                            value={newHeader}
                            onChange={(e) => setNewHeader(e.target.value)}
                        />
                    </Accordion.Header> 
                    <Accordion.Body>
                        <FontAwesomeIcon className="mx-1 cursor" onClick={handleNewSaveClick} icon={faCloudArrowUp}/>
                        <FontAwesomeIcon className="mx-1 cursor" onClick={handleNewDelete} icon={faTrash}/>
                        <ReactQuill
                            value={newData}
                            onChange={handleNewDataChange}
                            modules={{
                                toolbar: [
                                  ['bold', 'italic', 'underline'],
                                ],
                            }}
                            formats={[
                                'bold', 'italic', 'underline',
                            ]}
                        />
                    </Accordion.Body>
                </Accordion.Item>
              ):(<p></p> )}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}
